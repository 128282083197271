import {useAppSelector, useBreakPoint, useFeatureToggle} from '@hooks';
import {
  getEnabledFeaturesLoadingStatus,
  getIsBuildingsLoaded,
  getIsLoadingBuildings,
  getUnreadNotifications,
  getUser,
  getUserInitials,
  useCustomerBranding,
  useHomeBuildingId,
  useHomePageSections,
  useHomeTime,
} from '@lib/store';
import {FlexCol, FlexRow, H1} from '@quarks';
import {addDays, format, isSameDay, setHours, setMinutes, startOfDay} from 'date-fns';
import {useTranslation} from 'react-i18next';
import {Sidebar} from './Components/Sidebar/Sidebar';
import {
  AvailableRoomsTile,
  AvailableWorkspacesTile,
  ConnectionsTile,
  DiscoverTheOfficeTile,
  MeetingConsentTile,
  NextWorkdaysSimplifiedTile,
  NextWorkdaysTile,
  Tile,
  UpcomingMeetingsTile,
  WorkdayTile,
} from '@organisms';
import styled from 'styled-components';
import {BREAKPOINTS} from '@constants';
import {Icon, Loader} from '@atoms';
import {cn} from '@utils';
import {ReactNode, useMemo} from 'react';
import {ErrorBoundary, FallbackProps} from 'react-error-boundary';
import {CustomerBanner} from './Components/CustomerBanner/CustomerBanner';
import {NavLink} from 'react-router-dom';
import {Avatar, Badge} from '@molecules';

export const Home = () => {
  const enabledFeatures = useFeatureToggle();

  // Use automatically updating time. Note: using this will cause repeated renders of the whole component every tick
  const {time, timeDisplay, todayDate, tomorrowDate, setDebugTime, usesDebugTime, partOfDay} = useHomeTime(20_000);

  const flagsLoading = useAppSelector(getEnabledFeaturesLoadingStatus) !== 'Loaded';
  const buildingsAreLoading = useAppSelector(getIsLoadingBuildings);
  const buildingsHaveLoaded = useAppSelector(getIsBuildingsLoaded);
  const todayKey = format(todayDate, 'yyyy-MM-dd');

  const referenceDate = partOfDay === 'Evening' ? tomorrowDate : todayDate;
  const referenceDateKey = format(referenceDate, 'yyyy-MM-dd');
  const sections = useHomePageSections(referenceDateKey, partOfDay, enabledFeatures);
  const customerBranding = useCustomerBranding({enabled: enabledFeatures.CustomerBranding});
  // In practice, we will hide sensor-based data in the evenings, but just to be sure
  // we will create two building ids
  const buildingIdToday = useHomeBuildingId(todayKey);
  const buildingIdReferenceDate = useHomeBuildingId(referenceDateKey);
  const showLoading =
    flagsLoading ||
    !buildingsHaveLoaded ||
    !buildingIdToday ||
    !buildingIdReferenceDate ||
    buildingsAreLoading ||
    customerBranding.loadingStatus === 'Loading';

  // TODO: some memoization might help to prevent re-rendering of tiles on every tick
  //       at the same time, we might not need to worry about it if we make sure we don't
  //       do slow or weird things in these sections
  const newTiles = useMemo(() => {
    if (!buildingIdReferenceDate || !buildingIdToday) return null;

    return sections.map((s) => {
      // If these tiles had the same props, this would be a bit cleaner
      switch (s.componentKey) {
        case 'WorkdayTile':
          return wrapInKeyedErrorBoundary(
            s.componentKey,
            <WorkdayTile
              time={time}
              date={referenceDate}
            />,
          );
        case 'UpcomingMeetingsTile':
          return wrapInKeyedErrorBoundary(
            s.componentKey,
            <UpcomingMeetingsTile
              partOfDay={partOfDay}
              date={referenceDate}
              time={time}
            />,
          );
        case 'MeetingConsentTile':
          return wrapInKeyedErrorBoundary(s.componentKey, <MeetingConsentTile />);
        case 'AvailableRoomsTile':
          return wrapInKeyedErrorBoundary(
            s.componentKey,
            <AvailableRoomsTile
              time={time}
              buildingId={buildingIdToday}
            />,
          );
        case 'AvailableWorkspacesTile':
          return wrapInKeyedErrorBoundary(
            s.componentKey,
            <AvailableWorkspacesTile
              time={time}
              buildingId={buildingIdToday}
            />,
          );
        case 'DiscoverTheOfficeTile':
          return wrapInKeyedErrorBoundary(
            s.componentKey,
            <DiscoverTheOfficeTile buildingId={buildingIdReferenceDate} />,
          );
        case 'NextOfficeDaysTile':
          return wrapInKeyedErrorBoundary(s.componentKey, <NextWorkdaysTile date={referenceDate} />);
        case 'NextOfficeDaysTileSimplified':
          return wrapInKeyedErrorBoundary(s.componentKey, <NextWorkdaysSimplifiedTile referenceDate={referenceDate} />);
        case 'ConnectionsTile':
          return wrapInKeyedErrorBoundary(s.componentKey, <ConnectionsTile date={referenceDate} />);
        case 'ExtrasTile':
          return null;
      }
    });
  }, [buildingIdReferenceDate, buildingIdToday, referenceDate, sections, time, partOfDay]);

  const shouldShowBanner =
    enabledFeatures.CustomerBranding && customerBranding.branding.title && customerBranding.loadingStatus === 'Loaded';

  return (
    <>
      <HomeHeader_ExperienceImprovements
        time={time}
        referenceDate={referenceDate}
      />

      <HomeLayoutWrapper style={enabledFeatures.ExperienceImprovements ? {gridRow: 'canvas'} : undefined}>
        {shouldShowBanner && (
          <CustomerBanner
            title={customerBranding.branding.title}
            logoSrc={customerBranding.branding.logoUrl}
            bannerSrc={customerBranding.branding.bannerUrl}
          />
        )}
        <HomeLayout className={cn({'grid-rows-1': enabledFeatures.ExperienceImprovements})}>
          <FixedHeader className={cn({'hidden sm:block': enabledFeatures.ExperienceImprovements})}>
            <Greeting
              time={time}
              referenceDate={referenceDate}
            />

            {enabledFeatures.AppHomePage_DebugTime ? (
              <FlexRow
                className="absolute top-0 right-0 p-2 border-black bg-slate-400 rounded-b-lg"
                alignItems="center"
                gap={8}>
                <input
                  readOnly={!usesDebugTime}
                  onChange={(e) => {
                    const [h, m] = e.target.value.split(':').map(Number);
                    if (typeof h !== 'number' || typeof m !== 'number') return;

                    const today = startOfDay(time);

                    const atHour = setHours(today, h);
                    const atMinute = setMinutes(atHour, m);

                    setDebugTime(atMinute);
                  }}
                  type="time"
                  value={timeDisplay}
                />
                <label>
                  <input
                    type="checkbox"
                    defaultChecked={usesDebugTime}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setDebugTime(time);
                      } else {
                        setDebugTime(null);
                      }
                    }}
                  />
                  Override
                </label>
              </FlexRow>
            ) : null}
          </FixedHeader>

          <HomeBody
            as="main"
            justifyContent="flex-start"
            gap={16}>
            {showLoading ? (
              <FlexRow
                justifyContent="center"
                className="m-auto">
                <Loader />
              </FlexRow>
            ) : (
              newTiles
            )}
          </HomeBody>

          <HomeSidebar
            gap={32}
            justifyContent="flex-start">
            <Sidebar date={referenceDate} />
          </HomeSidebar>
          {/* Body */}
        </HomeLayout>
      </HomeLayoutWrapper>
    </>
  );
};

const Greeting = ({time, referenceDate}: {time: Date; referenceDate: Date}) => {
  const {t} = useTranslation();
  const bp = useBreakPoint();
  const enabledFeatures = useFeatureToggle();
  const tomorrow = addDays(time, 1);

  const title = isSameDay(time, referenceDate)
    ? t('Today')
    : isSameDay(tomorrow, referenceDate)
    ? t('Tomorrow')
    : // This case should not be hit unless we decide the page will show stuff
      // further in the future (e.g. show Monday on a Satruday)
      format(referenceDate, 'dd MMMM');

  return (
    <div className="flex justify-between w-full">
      <H1
        className={cn('flex w-full items-center gap-4 text-black', {
          'flex-row-reverse': bp === 'small' && !enabledFeatures.CustomerBranding,
          'justify-between': bp === 'small',
        })}>
        {title}
      </H1>
      {!enabledFeatures.ExperienceImprovements && <ProfileLink />}
    </div>
  );
};

const ProfileLink = () => {
  const user = useAppSelector(getUser);
  const userInitials = useAppSelector(getUserInitials);
  const bp = useBreakPoint();

  return (
    <NavLink
      to="profile"
      className="sm:hidden">
      <Avatar
        className="shrink-0"
        shouldShowCheckIn
        size={bp === 'small' ? 'medium' : 'large'}
        user={{...user, initials: userInitials}}
      />
    </NavLink>
  );
};

const HomeHeader_ExperienceImprovements = ({time, referenceDate}: {time: Date; referenceDate: Date}) => {
  const {t} = useTranslation();
  const enabledFeatures = useFeatureToggle();
  const hasIncomingNotifications = !!useAppSelector(getUnreadNotifications).length;

  return (
    enabledFeatures.ExperienceImprovements && (
      <div
        className="flex sm:hidden items-center justify-center border-b sticky px-4 top-0 z-10 bg-white"
        style={{gridArea: 'header / fullbleed'}}>
        <div className="flex justify-between w-full">
          <Greeting
            time={time}
            referenceDate={referenceDate}
          />
          <div className="flex gap-2 items-center shrink-0">
            {enabledFeatures.ExperienceImprovements && (
              <NavLink
                className={'flex text-mapiq-black-800* relative'}
                aria-label={t('screen:Notifications')}
                data-testid="organisms-navigation-notifications-link"
                to="notifications">
                {hasIncomingNotifications && (
                  <Badge
                    badge="notification"
                    right="0"
                    size="small"
                    top="0"
                  />
                )}
                <Icon
                  color={'currentColor'}
                  icon="bell"
                  className="size-6 shrink-0"
                />
              </NavLink>
            )}

            <ProfileLink />
          </div>
        </div>
      </div>
    )
  );
};

const wrapInKeyedErrorBoundary = (key: string, node: ReactNode) => (
  <ErrorBoundary
    key={key}
    FallbackComponent={Fallback}>
    {node}
  </ErrorBoundary>
);

const Fallback = ({error}: FallbackProps) => {
  const {t} = useTranslation();
  return (
    <Tile title={t('error:Ooops')}>
      <h3 className="text-functional-red">
        {error.message ? t('error:ErrorMessage', {errorText: error.message}) : t('error:GenericError')}
      </h3>
    </Tile>
  );
};

const HomeLayoutWrapper = styled.div`
  background: #fdfaf7;
  grid-area: canvas;
  --verticalOffset: 60px; // This one should match the small header
  --headerHeight: 90px;
  --headerFadeHeight: 20px;

  --mainWidth: 800px;
  --sideWidth: 320px;
  --gutterWidth: 2rem;
  --homeLayoutWidth: calc(var(--mainWidth) + var(--sideWidth) + var(--gutterWidth));

  ${BREAKPOINTS.large`
    --homeLayoutWidth: 1000px;
  `}

  ${BREAKPOINTS.small`
    grid-row: header / canvas;
  `}
`;

const HomeLayout = styled('article')`
  max-width: var(--homeLayoutWidth);
  margin: 0 auto;

  position: relative;
  display: grid;

  grid-template-rows: var(--headerHeight) 1fr;
  grid-template-columns: 2.5fr 1fr;
  column-gap: var(--gutterWidth);

  justify-content: center;

  ${BREAKPOINTS.large`
    --verticalOffset: 100px;
    grid-row: header / canvas;

    grid-template-rows: var(--verticalOffset) auto auto;
    grid-template-columns: 1fr;
    grid-template-areas:
      'header-main'
      'main'
      'sidebar';

    grid-column-gap: 0;
    grid-row-gap: 0 1rem;
    max-width: var(--homeLayoutWidth); 
    height: auto;
    padding: 0 var(--gutterWidth);

  `}

  ${BREAKPOINTS.medium`
    padding: 0 var(--gutterWidth);
  `}

  ${BREAKPOINTS.small`
    --verticalOffset: 60px;
    max-width: unset;
    padding: 0;
  `}
`;

const FixedHeader = styled('header')`
  position: sticky;
  z-index: 1;
  padding-top: 1rem;
  top: 45px;

  width: 100%;
  height: var(--headerHeight);

  background: linear-gradient(
    180deg,
    #fdfaf7 calc(var(--headerHeight) - var(--headerFadeHeight)),
    rgba(255, 255, 255, 0) 100%
  );

  display: flex;
  align-items: center;

  ${BREAKPOINTS.large`
    background: transparent;
    top: 0px;

  `}

  ${BREAKPOINTS.small`
    padding: 0 1rem;
    height: var(--verticalOffset);
    top: 0;
    background: white;
    border-bottom: 1px solid #e8e8e8;
  `}
`;

const HomeBody = styled(FlexCol)`
  grid-row: header-main / main;

  // For scrollbar
  overflow-y: auto;
  padding-right: 20px;

  // Space at bottom of scroll container
  padding-bottom: 100px;

  ${BREAKPOINTS.large`
    grid-row: unset;
    grid-area: main;
    padding: 0;
    padding-bottom: 4rem;
  `}

  ${BREAKPOINTS.small`
    grid-row: unset;
    grid-area: main;
    padding-bottom: 1rem;
  `}
`;

const HomeSidebar = styled(FlexCol)`
  grid-row: header-sidebar / sidebar;
  max-height: calc(100vh - var(--verticalOffset));
  position: sticky;
  align-self: start;
  top: 90px;
  // For scrollbar
  overflow-y: auto;
  padding-right: 20px;

  // Space at bottom of scroll container
  padding-bottom: 100px;

  ${BREAKPOINTS.large`
    grid-row: unset;
    grid-area: sidebar;
    padding: 0;
  `}
`;
