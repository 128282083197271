import {useTranslation} from 'react-i18next';
import {Button} from '@molecules';
import {FlexCol, H1, P} from '@quarks';
import {CardBody, CardBottom, CardTop} from '../styles';
import img from '@images/onboarding/preferencesIntro.webp';
import {OnboardingWrapper} from '@organisms';
import {BackButton} from '../components';

type OnboardingPreferencesIntroCardProps = {
  onNext: () => void;
  onBack?: () => void;
};

export const OnboardingPreferencesIntroCard = ({onNext, onBack}: OnboardingPreferencesIntroCardProps) => {
  const {t} = useTranslation();

  return (
    <OnboardingWrapper>
      <CardTop>
        <FlexCol gap={16}>
          {onBack && <BackButton onBack={onBack} />}

          <H1 id="onboarding-legend">{t('profile:PreferencesTitle')}</H1>
          <P>{t('profile:PreferencesDescription')}</P>
        </FlexCol>
      </CardTop>

      <CardBody className="min-h-40 py-4">
        <img
          alt={t('profile:PreferencesDescription')}
          src={img}
          className="object-contain"
        />
      </CardBody>

      <CardBottom>
        <Button
          button="primary"
          onClick={onNext}
          size="full">
          {t('Next')}
        </Button>
      </CardBottom>
    </OnboardingWrapper>
  );
};
