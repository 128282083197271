import {useFeatureToggle} from '@hooks';
import {
  withAsyncThunkErrorHandling,
  savePreferredBuilding,
  setWorkspaceLocation,
  setParkingLocation,
  useAppDispatch,
  useAppSelector,
  getOnboardingState,
  getIsParkingFeatureVisible,
  getUser,
  setIsDeskRequired as setDeskRequired,
  getDefaultBuildingId,
  getFilteredAndSortedParkingLots,
} from '@lib/store';
import {useAreaOptions, useDeskAmenityOptions} from '@lib/utils/src/hooks/preferences';
import {useState} from 'react';
import {OnboardingStep} from 'src/components/organisms/onboarding/types';

export const useOnboarding = () => {
  const dispatch = useAppDispatch();
  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);
  const user = useAppSelector(getUser);
  const {ExperienceImprovements: isExperienceImprovementsEnabled} = useFeatureToggle();

  // In the initial onboarding, defaultBuildingId will be `null` for new users,
  // we default to an empty string to satisfy subsequent cached selector keys that use this value
  const defaultBuildingId = (useAppSelector(getDefaultBuildingId) as string | null) ?? '';
  const {floorId, areaId, deskAmenities, parkingLotId} = useAppSelector(getOnboardingState);

  const areaOptions = useAreaOptions({buildingId: defaultBuildingId, floorId});
  const areaSelectableOptions = areaOptions.filter((area) => area.value !== null);

  const {deskAmenityOptions} = useDeskAmenityOptions({buildingId: defaultBuildingId, floorId, areaId});

  const allLots = useAppSelector((state) => getFilteredAndSortedParkingLots(state, defaultBuildingId));
  const showParkingLotsSelection = isParkingFeatureVisible && allLots.length > 0;

  const [step, setStep] = useState(
    isExperienceImprovementsEnabled ? OnboardingStep.Intro : OnboardingStep.BookingPermissions,
  );

  const saveBuilding = async (buildingId?: string | null) => {
    if (buildingId === undefined) return;

    const isNew = !!isExperienceImprovementsEnabled;

    const result = await dispatch(withAsyncThunkErrorHandling(() => savePreferredBuilding({buildingId, isNew, user})));

    if (!result.success) return;

    if (isExperienceImprovementsEnabled) {
      setStep(OnboardingStep.Floor);
    }
  };

  const setFloor = (selectedFloorId: string | null) => {
    dispatch(setWorkspaceLocation({floorId: selectedFloorId, areaId, deskAmenities}));

    // If user has no floor selected, skip area and desk amenities selection
    if (!selectedFloorId) {
      setStep(showParkingLotsSelection ? OnboardingStep.ParkingLot : OnboardingStep.Overview);
    }

    setStep(areaSelectableOptions ? OnboardingStep.Area : OnboardingStep.Overview);
  };

  const setArea = (selectedAreaId: string | null) => {
    dispatch(setWorkspaceLocation({floorId, areaId: selectedAreaId, deskAmenities}));

    if (deskAmenityOptions.length) {
      setStep(OnboardingStep.DeskAmenities);
      return;
    }

    if (showParkingLotsSelection) {
      setStep(OnboardingStep.ParkingLot);
      return;
    }

    setStep(OnboardingStep.Overview);
  };

  const setDeskAmenities = (selectedDeskAmenities: string[]) => {
    dispatch(setWorkspaceLocation({floorId, areaId, deskAmenities: selectedDeskAmenities}));

    if (showParkingLotsSelection) {
      setStep(OnboardingStep.ParkingLot);
      return;
    }

    setStep(OnboardingStep.Overview);
  };

  const setParkingLot = (selectedParkingLotId: string | null) => {
    dispatch(setParkingLocation({parkingLotId: selectedParkingLotId}));

    const zonesForSelectedLot = (allLots.find((l) => l.id === selectedParkingLotId)?.parkingZones ?? []).filter(
      (parkingZone) => parkingZone.capacity > 0,
    );

    if (!selectedParkingLotId || !zonesForSelectedLot.length) {
      setStep(OnboardingStep.Overview);
      return;
    }

    setStep(OnboardingStep.ParkingZone);
  };

  const setParkingZone = (selectedParkingZoneId: string | null) => {
    dispatch(setParkingLocation({parkingLotId, parkingZoneId: selectedParkingZoneId}));

    setStep(OnboardingStep.Overview);
  };

  const setIsDeskRequired = (value: boolean) => {
    dispatch(setDeskRequired(value));
  };

  return {
    step,
    setStep,

    saveBuilding,
    setFloor,
    setArea,
    setDeskAmenities,
    setParkingLot,
    setParkingZone,
    setIsDeskRequired,

    showParkingLotsSelection,
  };
};
