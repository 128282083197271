import {Icon, Loader} from '@atoms';
import {useModal} from '@hooks';
import {
  createWorkday,
  registerWorkspaceReservationWithPreferredLocation,
  getPreferences,
  getWorkdayByDate,
  useAppDispatch,
  useAppSelector,
  withAsyncThunkErrorHandling,
  getWorkspaceReservationByDate,
  WorkdayStatus,
  getDefaultBuilding,
  getIsWorkspaceReservationButtonEnabled,
  getIsParkingFeatureVisible,
  getParkingReservationByDate,
  getParkingSettings,
  getLicensePlates,
  reserveParkingSpotWithPreferredLocation,
  getIsUserAllowedToReserveParking,
  getParkingLotById,
} from '@lib/store';
import {IconButton} from '@molecules';
import {BookingLocationStatusCard, BookWorkspaceCard, ParkingSelectionCard} from '@organisms';
import {cn, IconStatusMapping} from '@utils';
import {parseISO} from 'date-fns';
import React, {Dispatch, SetStateAction, useEffect, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {LicensePlatesModalPage} from 'src/components/organisms/profile/licensePlates/LicensePlatesModalPage/LicensePlatesModalPage';
import {ErrorObject} from '../types';

type Props = {
  disabled: boolean;
  date: string;

  processingFor: WorkdayStatus | null;
  setProcessingFor: Dispatch<SetStateAction<WorkdayStatus | null>>;

  loadingWorkplace: boolean;
  setLoadingWorkplace: Dispatch<SetStateAction<boolean>>;
  loadingParking: boolean;
  setLoadingParking: Dispatch<SetStateAction<boolean>>;

  errorObject: ErrorObject;
  setErrorObject: Dispatch<SetStateAction<ErrorObject>>;
};

export const QuickOfficeDayButton = ({
  disabled,
  date,
  loadingWorkplace,
  setLoadingWorkplace,
  loadingParking,
  setLoadingParking,
  errorObject,
  setErrorObject,
  processingFor,
  setProcessingFor,
}: Props) => {
  const forWorkdayStatus: WorkdayStatus = 'OfficeDay' as const;

  const {t} = useTranslation();
  const dispatch = useAppDispatch();
  const {workspaceLocation, parkingLocation} = useAppSelector(getPreferences);
  const parkingSettings = useAppSelector(getParkingSettings);
  const {addPages, openModal} = useModal();
  const defaultBuilding = useAppSelector(getDefaultBuilding);

  const dateAsDate = useMemo(() => parseISO(date), [date]);

  const isParkingFeatureVisible = useAppSelector(getIsParkingFeatureVisible);
  const workspaceReservationByDate = useAppSelector((state) => getWorkspaceReservationByDate(state, dateAsDate));
  const parkingReservationByDate = useAppSelector((state) => getParkingReservationByDate(state, date));
  const workdayByDate = useAppSelector((state) => getWorkdayByDate(state, date));
  const licensePlates = useAppSelector(getLicensePlates);

  const buildingId = workdayByDate?.nodeId ?? defaultBuilding?.id;

  const isWorkdaySetInDefaultBuilding = defaultBuilding?.id === buildingId;

  const hasOptimisticWorkday = workdayByDate?.status || processingFor === forWorkdayStatus;

  const canBookWorkspace = useAppSelector((state) => getIsWorkspaceReservationButtonEnabled(state, dateAsDate));
  const isUserAllowedToReserveParking = useAppSelector((state) =>
    buildingId ? getIsUserAllowedToReserveParking(state, dateAsDate, buildingId) : false,
  );
  const showParking = hasOptimisticWorkday && !parkingReservationByDate && isParkingFeatureVisible;
  const canBookParking = isUserAllowedToReserveParking && showParking;

  const defaultParkingLot = useAppSelector((state) => getParkingLotById(state, parkingLocation?.parkingLotId || ''));
  // has configured preferences and quota / profiles allow workspace booking on this day
  const canUseAutoBookingFlow =
    !!workspaceLocation?.floorId &&
    isWorkdaySetInDefaultBuilding &&
    workspaceLocation.isDeskRequired &&
    canBookWorkspace;

  const canUseParkingAutoBookingFlow =
    !!parkingLocation?.parkingLotId &&
    buildingId &&
    defaultParkingLot?.buildingIds.includes(buildingId) &&
    canBookParking;

  const allowWorkdayOnly = defaultBuilding?.allowWorkdayWithoutWorkspace ?? false;
  const isLicensePlateMandatory = parkingSettings?.licensePlateIsRequired ?? false;

  // currently only isLicensePlateMandatory is only set ins BE
  const shouldOpenLicensePlateModal = isLicensePlateMandatory && !licensePlates?.length;

  const saveWorkday = async () => {
    if (!defaultBuilding) return;
    setProcessingFor(forWorkdayStatus);

    const {success} = await dispatch(
      withAsyncThunkErrorHandling(() =>
        createWorkday({
          nodeId: defaultBuilding.id,
          status: forWorkdayStatus,
          endDate: date,
          startDate: date,
        }),
      ),
    );

    setProcessingFor(null);
  };

  const autoWorkspaceBooking = async () => {
    const {floorId, areaId} = workspaceLocation || {};
    if (!floorId) return;
    setLoadingWorkplace(true);
    const errorObjectKey = allowWorkdayOnly ? 'workplace' : 'workday';
    setErrorObject((p) => ({
      ...p,
      [errorObjectKey]: null,
    }));
    setProcessingFor(forWorkdayStatus);

    try {
      const result = await dispatch(
        registerWorkspaceReservationWithPreferredLocation({
          selectedDate: date,
        }),
      ).unwrap();
      setLoadingParking(false);

      if (
        (areaId && result.currentMember?.location.areaId !== areaId) ||
        (floorId && result.currentMember?.location.floorId !== floorId)
      ) {
        setErrorObject((p) => ({
          ...p,
          [errorObjectKey]: t('workplace:AlternativePick'),
        }));
      }

      setProcessingFor(null);
      setLoadingWorkplace(false);
    } catch (error) {
      setErrorObject((p) => ({
        ...p,
        [errorObjectKey]: t('workplace:CouldntFindASpot'),
      }));
      setProcessingFor(null);
      setLoadingWorkplace(false);
    }
  };
  const autoParkingBooking = async () => {
    const {parkingLotId} = parkingLocation || {};
    if (!parkingLotId) return;
    setLoadingParking(true);
    setErrorObject((p) => ({
      ...p,
      parking: null,
    }));

    try {
      await dispatch(
        reserveParkingSpotWithPreferredLocation({
          selectedDate: date,
        }),
      ).unwrap();
      setLoadingParking(false);
    } catch (error) {
      setErrorObject((p) => ({
        ...p,
        parking: t('parking:CouldntFindASpot'),
      }));
      setLoadingParking(false);
    }
  };

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (workdayByDate) {
      addPages([<BookingLocationStatusCard date={date} />]);
      openModal();
    } else if (canUseAutoBookingFlow && !allowWorkdayOnly) {
      autoWorkspaceBooking();
    } else if (allowWorkdayOnly) {
      saveWorkday();
    } else {
      addPages([<BookingLocationStatusCard date={date} />]);
      openModal();
    }
  };

  const handleAddWorkspace = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!canBookWorkspace) return;

    if (canUseAutoBookingFlow) {
      autoWorkspaceBooking();
    } else {
      addPages([<BookWorkspaceCard date={date} />]);
      openModal();
    }
  };

  const handleAddParking = async (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (!canBookParking || !buildingId) return;

    if (canUseParkingAutoBookingFlow) {
      autoParkingBooking();
    } else {
      if (shouldOpenLicensePlateModal) {
        addPages([
          <LicensePlatesModalPage
            date={date}
            buildingId={buildingId}
          />,
        ]);
      } else {
        addPages([
          <ParkingSelectionCard
            date={date}
            buildingId={buildingId}
          />,
        ]);
      }

      openModal();
    }
  };

  useEffect(
    function resetErrorWhenWorkspaceIsBookedOutside() {
      if (errorObject.workplace && workspaceReservationByDate) {
        setErrorObject((p) => ({
          ...p,
          workday: null,
          workplace: null,
        }));
      }
    },
    [workspaceReservationByDate, errorObject.workplace, setErrorObject],
  );

  useEffect(
    function resetErrorWhenParkingIsBookedOutside() {
      if (errorObject.parking && parkingReservationByDate) {
        setErrorObject((p) => ({
          ...p,
          parking: null,
        }));
      }
    },
    [parkingReservationByDate, errorObject.parking, setErrorObject],
  );

  return (
    <>
      <IconButton
        className={cn('shrink-0', {
          'bg-energizing-yellow-500*': hasOptimisticWorkday && !disabled,
        })}
        disabled={disabled || !!errorObject.workday}
        iconButton="tertiary"
        icon={IconStatusMapping[forWorkdayStatus]}
        onClick={handleClick}
      />

      {!loadingWorkplace && hasOptimisticWorkday && !workspaceReservationByDate && (
        <button
          disabled={disabled || !canBookWorkspace || !!errorObject.workplace}
          className="group transition-colors bg-mapiq-black-800*/[0.03] hover:bg-mapiq-black-800*/[0.06] disabled:pointer-events-none shadow-mapiq-base rounded-md p-2 flex gap-1.5 items-center order-1"
          onClick={handleAddWorkspace}>
          <Icon
            icon="plus"
            color="currentColor"
            className={'size-4 group-disabled:text-mapiq-black-400'}
          />
          <Icon
            icon="desk"
            color="currentColor"
            className={'size-4 group-disabled:text-mapiq-black-400'}
          />
        </button>
      )}

      {!loadingWorkplace && workspaceReservationByDate && (
        <button
          disabled={disabled}
          className="group bg-mapiq-black-800*/[0.03] rounded-md p-2 flex gap-1.5 max-w-[14ch] items-center order-1">
          <span className="shrink-0 relative ">
            <span
              className={cn(
                'block absolute top-0 right-0 bg-functional-red size-1.5 rounded-full border-white opacity-0',
                {
                  'animate-in fade-in fill-mode-forwards': errorObject.workplace,
                  'animate-out fade-out fill-mode-forwards': !errorObject.workplace,
                },
              )}
            />
            <Icon
              icon="desk"
              color="currentColor"
              className={'size-4 group-disabled:text-mapiq-black-400'}
            />
          </span>
          {!showParking && !parkingReservationByDate && (
            <p
              className="truncate leading-none"
              title={workspaceReservationByDate.currentMember?.location.areaName}>
              {workspaceReservationByDate.currentMember?.location.areaName}
            </p>
          )}
          <span className="size-4 bg-connecting-green-500* rounded-[4px] flex justify-center items-center shrink-0  group-disabled:opacity-50">
            <Icon
              icon="check"
              color="white"
              className="size-3"
            />
          </span>
        </button>
      )}

      {!loadingParking && showParking && hasOptimisticWorkday && !parkingReservationByDate && (
        <button
          disabled={disabled || !canBookParking || !!errorObject.parking}
          className="group transition-colors bg-mapiq-black-800*/[0.03] hover:bg-mapiq-black-800*/[0.06] disabled:pointer-events-none shadow-mapiq-base rounded-md p-2 flex gap-1.5 items-center order-2"
          onClick={handleAddParking}>
          <Icon
            icon="plus"
            color="currentColor"
            className={'size-4 group-disabled:!text-mapiq-black-400'}
          />
          <Icon
            icon="car"
            color="currentColor"
            className={'size-4 group-disabled:!text-mapiq-black-400'}
          />
        </button>
      )}

      {!loadingParking && parkingReservationByDate && (
        <button
          disabled={disabled}
          className="group bg-mapiq-black-800*/[0.03] rounded-md p-2 flex gap-1.5 max-w-[14ch] items-center order-2">
          <span className="shrink-0 relative">
            <span
              className={cn(
                'block absolute top-0 right-0 bg-functional-red size-1.5 rounded-full border-white opacity-0',
                {
                  'animate-in fade-in fill-mode-forwards': errorObject.parking,
                  'animate-out fade-out fill-mode-forwards': !errorObject.parking,
                },
              )}
            />
            <Icon
              icon="car"
              color="currentColor"
              className={'size-4 group-disabled:text-mapiq-black-400'}
            />
          </span>
          <span className="size-4 bg-connecting-green-500* rounded-[4px] flex justify-center items-center shrink-0 group-disabled:opacity-50">
            <Icon
              icon="check"
              color="white"
              className="size-3"
            />
          </span>
        </button>
      )}

      {(loadingWorkplace || loadingParking) && (
        <div
          className={cn('bg-mapiq-black-800*/[0.03] rounded-md p-2 flex gap-1.5 items-center order-3', {
            'order-1': loadingWorkplace && !loadingParking,
          })}>
          <Loader className="size-4" />
          <small className="truncate leading-none">{t('workplace:FindingASpot')}</small>
        </div>
      )}
    </>
  );
};
